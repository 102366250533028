$narrow_width: 500px;
$wide_width: 960px;
$maintext_max: 768px;

// Base16: http://chriskempson.com/projects/base16/
$colorBackground: #181818;
$colorForeground: #d8d8d8;
$colorDarkForeground: #b8b8b8;
$colorLighterBackground: #282828;
$colorComments: #585858;
$colorRed: #ab4642;
$colorOrange: #dc9656;
$colorYellow: #f7ca88;
$colorGreen: #a1b56c;
$colorAqua: #86c1b9;
$colorBlue: #7cafc2;
$colorPurple: #ba8baf;
$colorBrown: #a16946;

html, body {
  height: 100%;
  background: $colorBackground;
}
article {
  font-family: serif;
  font-size: 1.25em;
  line-height: 1.5;
  color: $colorForeground;
}
footer {
  font-family: serif;
  color: $colorForeground;
  background: $colorLighterBackground;
}
header {
  font-family: sans-serif;
  line-height: 1.3;
  text-align: center;
  color: white;
}
h1, h2, h3, h4, h5{
  font-family: sans-serif;
}
h1 {
  line-height: 1.3;
  font-weight: bold;
  font-size: 3em;
  // Make sure the layout isn't broken by accident
  word-break: break-word;
}
h2, a.section-h2 {
  font-weight: bold;
  font-size: 1.5em;
  small {
    font-size: 50%;
  }
}

hr {
  color: $colorLighterBackground;

  width: 50%;
  &.fullwidth {
    width: 100%;
  }
}

.show-narrow {
  @media (min-width: $narrow_width) {
    display: none;
  }
}
.hide-narrow {
  @media (max-width: $narrow_width) {
    display: none;
  }
}

@media (min-width: $wide_width) {
  body {
    display: flex;
    flex-flow: row nowrap;
  }
  header {
    overflow-y: auto;
  }
  #container-content {
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    height: 100%;
    flex: 1 1 auto;
  }
  header {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: auto;
    padding: 0.25em 0;
  }
}
#home-button {
  margin-top: 1em;
  margin-left: 1em;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  border: 0.125em solid black;
  border-radius: 0.5em;

  background: white;
  background-image: url(/assets/icons/home.svg);
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
    background: $colorDarkForeground;
    background-image: url(/assets/icons/home.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}
#sidebar-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.bubbles {
  display: flex;
  flex-flow: row wrap;
  margin: 1em 1em 1em 1em;

  a {
    text-decoration: none;
    padding: 0.25em;
    border: 0.125em solid black;
    border-radius: 0.5em;
    background: white;
    &:hover {
      background: $colorDarkForeground;
    }
  }
}
#sidebar-links {
  justify-content: flex-end;

  .button.icon {
    min-height: 1em;
    min-width: 1em;
    background-size: 1em;
    background-position: center;
    background-repeat: no-repeat;
  }
}
#sidebar-pagination {
  justify-content: space-between;
  a {
    text-transform: uppercase;
  }
}

.site-title {
  -webkit-text-stroke: 2px black;
}

.site-header {
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(1);
  @media (max-width: $wide_width) { // Not Wide Screens
    flex-flow: column nowrap;
    justify-content: space-between;
    min-height: 20em;  /* Show Faye's face */
    padding: 2.5em 0 1em 0;
    background-image: url(/assets/sidebar_narrow.jpg);
  }
  @media (min-width: $wide_width) { // Wide Screens
    background-image: url(/assets/sidebar_wide.jpg);
    flex: 0 1 35%;  // Ocuppy at most 35% of top container
    flex-flow: column nowrap;
    justify-content: space-between;
  }
}

#presentation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  div.presentation-rows {
    width: 100%;
  }

  dl.presentation-row {
    display: flex;
    flex-flow: row nowrap;

    dt {
      flex: 1 1 40%;
      text-align: right;
      font-variant: small-caps;
      font-weight: 500;
      margin-right: 0.5em;
    }
    dd {
      flex: 1 1 60%;
      text-align: left;
      margin-left: 0.5em;
    }
  }
  .presentation-text {
    text-align: justify;
  }
}

#extra-info {
  ul {
    display: flex;
    flex-direction: column;
    gap: .25em;
  }
}

.metadata {
  color: $colorComments;

  li {
    list-style-type: none;
    margin-bottom: auto;
  }

  .post-metadata, .tag-metadata {
    color: $colorForeground;
  }
}

.deemphasize {
  color: $colorLighterBackground;
  &.size {
    font-size: 0.625em;  // De-emphasize this
  }
}

article, footer {
  a {
    color: $colorBlue;
    text-decoration: none;

    &:visited {
      color: $colorAqua;
    }
    &:hover {
      text-decoration: underline;
    }
    &.deemphasize {
      color: $colorLighterBackground;
      text-decoration: underline;
    }

  }
}
footer {
  padding: 1em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .license-text {
    padding: 0 1em;
  }
}
article {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column wrap;
  align-content: center;

  .container {
    max-width: $maintext_max;
    padding: 1em;
  }
  h1 {
    color: $colorRed;
    font-size: 2em;
    text-align: center;
    padding-bottom: 0.5em;
  }
  h2 {
    color: $colorPurple;
  }
  // Tag List Sections
  .tag-list + h2, h2 + .tag-list {
    text-align: center;
  }
  h3 {
    color: $colorPurple;
  }
  p {
    margin-bottom: .75em;
    margin-top: .75em;
  }
  #page-content {
    text-align: justify;
  }
  li {
    margin-left: 1.5em;
    margin-bottom: .75em;
    list-style-type: disc;
  }
  blockquote {
    background: $colorLighterBackground;
    color: $colorDarkForeground;
    border-left: 0.125em solid $colorForeground;
    margin-left: 0.5em;
    padding: 0 0.5em;
    font-style: italic;

    em {
      font-style: normal;  // Counter the "italic" above
    }
  }
  sup {
    vertical-align: super;
    font-size: 75%;
  }
  // Sections
  div.section {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $colorOrange;
    a.section {
      color: inherit;
      flex: 0 0;  // Don't grow
    }
    h2 {
      color: inherit;
      text-align: center;
      flex: 1 0;  // Grow
    }
    // Show link only on hover, if supported
    @media (hover: hover) {
      h2 {
        text-align: start;
      }
      a.section {
        display: none;
      }
      &:hover a.section {
        display: initial;
      }
    }
  }

  // Footnotes
  .footnote::before {
    content: "[";
  }
  .footnote::after {
    content: "]";
  }
  .footnotes {
    margin-top: 1.5em;
    border-top: 1px solid $colorLighterBackground;
    li {
      list-style-type: decimal;
    }
  }

  .post-list-top {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-flow: row nowrap;

    .post-metadata {
      font-size: 0.875em;
      color: $colorGreen;

      &.relevance {
        font-size: 0.625em;  // De-emphasize this
      }
    }
    .post-title-metadata {
      font-size: 0.625em;

      &::before {
        content: "["
      }
      &::after {
        content: "]"
      }
    }
    a.stub {  // De-emphasize this
      color: $colorDarkForeground;
    }
    .title .aka {
      // De-emphasize this
      color: $colorDarkForeground;
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }

    @media (min-width: $wide_width) {
      .title {
        align-self: flex-start;
        flex: 2 1 auto;
      }
      .date {
        flex-shrink: 0;
      }
      .post-metadata {
        margin: auto 0 auto 1em;
      }
    }
    @media (max-width: $wide_width) {
      flex-flow: column nowrap;
      justify-content: center;
      text-align: center;
    }
  }
  .post-excerpt {
    text-align: justify;
  }

  ul.posts {
    & > li {
      list-style-type: none;
      margin: 1em auto;
      padding: 1em 1em 0 1em;
    }
  }

  ul.tag-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: center;

    li {
      list-style-type: none;
      margin: 0.5em;

      &.tag-list-element {
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: center;
        text-align: center;

        & .metadata {
          display: flex;
          flex-flow: column nowrap;
          align-content: center;
        }

        .stars {
          color: $colorGreen;
        }
      }
    }
  }

  img {
    max-width: 100%; /* Fallback */
    max-width: calc(100% - 2em);
    margin: 0 1em;
  }

  .external-frame {
    position: relative;
    height: 0;
    padding-top: 56.25%; // Inverse of Aspect Ratio, defaults to "16/9"

    & > iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
}

#post-metadata-linkbox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  @media (max-width: $wide_width) {
    flex-flow: column nowrap;
  }

  .extlink {
    padding: 0.125em 0.5em;
    border: 1px solid $colorBrown;
  }
}

#page-stars {
  display: block;
  margin: 1em 0;
  text-align: center;
  font-size: 2.5em;
  color: $colorGreen;
}

// Unpublished Stuff (includes visited links)
.unpublished,
a.unpublished:visited {
  color: $colorYellow;
}


a.extlink {
  &::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 1px;
    margin-right: .25em;
  }

  
  &.linkbox-imdb::before {
    background-image: url(/assets/linkbox/imdb.ico);
  }
  
  &.linkbox-wikipedia::before {
    background-image: url(/assets/linkbox/wikipedia.ico);
  }
  
  &.linkbox-ebert::before {
    background-image: url(/assets/linkbox/ebert.ico);
  }
  
}

.archives-ul {
  display: flex;
  flex-flow: row wrap;
}

#search-form {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
#search-results {
  .title {
    text-align: center;
  }
  .search-message {
    text-align: center;
    font-size: 1.25em;
  }
}
